import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import HomeLayout from "../components/HomeLayout/HomeLayout"
import { useMediaQuery } from "react-responsive"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Flippy from "react-flippy/dist/Flippy"
import { FrontSide, BackSide } from "react-flippy/dist/FlippyCard"
import CoolLightbox from "../components/LightBox/CoolLightBox"
import Slider from "react-slick"

const About = () => {
  const [isOpen, setOpen] = useState(false)
  const [currentImageIndex, setCurrentIndex] = useState(0)

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 720px)",
  })
  const [isFlipped1, setIsFlipped1] = useState(false)
  const [isFlipped2, setIsFlipped2] = useState(false)
  const [isFlipped3, setIsFlipped3] = useState(false)
  const [isFlipped4, setIsFlipped4] = useState(false)
  const [isFlipped5, setIsFlipped5] = useState(false)
  const [isFlipped6, setIsFlipped6] = useState(false)

  var settings = {
    infinite: false,
    arrows: isTabletOrMobileDevice ? false : true,
    dots: true,
  }
  const data = useStaticQuery(graphql`
    query {
      About: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "About" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      PUC: allFile(filter: { sourceInstanceName: { eq: "PUC" } }) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      ClientInfo: allFile(
        filter: { sourceInstanceName: { eq: "ClientInfo" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  var images = []
  data.ClientInfo.nodes.map(img => {
    var src = img.childImageSharp.fluid.src
    images.push({
      src,
    })
  })

  return (
    <Layout>
      <Slider {...settings}>
        <div>
          <HomeLayout>
            {/* Page1 */}
            {data.About.nodes.map((img, index) => {
              var name = img.name.split(" ")
              if (index === 1 || index === 2 || index === 0)
                return (
                  <Link to={`/About/${name[2]}`} key={img.id}>
                    <Img
                      fluid={{ ...img.childImageSharp.fluid, aspectRatio: 1 }}
                      alt="Home images"
                    />
                  </Link>
                )
            })}
            <div
              onClick={() => {
                if (!isFlipped1) {
                  setIsFlipped2(false)
                  setIsFlipped3(false)
                  setIsFlipped4(false)
                  setIsFlipped5(false)
                  setIsFlipped6(false)

                  setIsFlipped1(true)
                } else {
                  setIsFlipped1(false)
                }
                setTimeout(() => setIsFlipped1(false), 3000)
              }}
            >
              <Flippy
                flipDirection="horizontal"
                flipOnClick={false}
                isFlipped={isFlipped1}
              >
                <FrontSide>
                  <Img
                    fluid={data.About.nodes[3].childImageSharp.fluid}
                    alt="Front image"
                  />
                </FrontSide>
                <BackSide>
                  <Img
                    fluid={{
                      ...data.PUC.nodes[0].childImageSharp.fluid,
                      aspectRatio: 1,
                    }}
                    alt="Back image"
                    fadeIn={false}
                  />
                </BackSide>
              </Flippy>
            </div>
            <div
              onClick={() => {
                if (!isFlipped2) {
                  setIsFlipped1(false)
                  setIsFlipped3(false)
                  setIsFlipped4(false)
                  setIsFlipped5(false)
                  setIsFlipped6(false)

                  setIsFlipped2(true)
                } else {
                  setIsFlipped2(false)
                }
                setTimeout(() => setIsFlipped2(false), 3000)
              }}
            >
              <Flippy flipDirection="horizontal" isFlipped={isFlipped2}>
                <FrontSide>
                  <Img
                    fluid={data.About.nodes[4].childImageSharp.fluid}
                    alt="Front image"
                  />
                </FrontSide>
                <BackSide>
                  <Img
                    fluid={{
                      ...data.PUC.nodes[0].childImageSharp.fluid,
                      aspectRatio: 1,
                    }}
                    alt="Back image"
                    fadeIn={false}
                  />
                </BackSide>
              </Flippy>
            </div>
            <div
              onClick={() => {
                setOpen(true)
              }}
            >
              <Img
                fluid={data.About.nodes[5].childImageSharp.fluid}
                alt="Front image"
              />
            </div>
            <CoolLightbox
              images={images}
              setCurrentIndex={setCurrentIndex}
              currentImageIndex={currentImageIndex}
              isOpen={isOpen}
              onClose={() => setOpen(false)}
            />
          </HomeLayout>
        </div>
        {/* Page2 */}
        <div>
          <HomeLayout>
            <div
              onClick={() => {
                if (!isFlipped5) {
                  setIsFlipped2(false)
                  setIsFlipped3(false)
                  setIsFlipped1(false)
                  setIsFlipped4(false)
                  setIsFlipped6(false)

                  setIsFlipped5(true)
                } else {
                  setIsFlipped5(false)
                }
                setTimeout(() => setIsFlipped5(false), 3000)
              }}
            >
              <Flippy flipDirection="horizontal" isFlipped={isFlipped5}>
                <FrontSide>
                  <Img
                    fluid={data.About.nodes[7].childImageSharp.fluid}
                    alt="Front image"
                  />
                </FrontSide>
                <BackSide>
                  <Img
                    fluid={{
                      ...data.PUC.nodes[0].childImageSharp.fluid,
                      aspectRatio: 1,
                    }}
                    alt="Back image"
                    fadeIn={false}
                  />
                </BackSide>
              </Flippy>
            </div>
            <div
              onClick={() => {
                if (!isFlipped4) {
                  setIsFlipped2(false)
                  setIsFlipped1(false)
                  setIsFlipped3(false)
                  setIsFlipped5(false)
                  setIsFlipped6(false)

                  setIsFlipped4(true)
                } else {
                  setIsFlipped4(false)
                  
                }

                setTimeout(() => setIsFlipped4(false), 3000)
              }}
            >
              <Flippy flipDirection="horizontal" isFlipped={isFlipped4}>
                <FrontSide>
                  <Img
                    fluid={data.About.nodes[6].childImageSharp.fluid}
                    alt="Front image"
                  />
                </FrontSide>
                <BackSide>
                  <Img
                    fluid={{
                      ...data.PUC.nodes[0].childImageSharp.fluid,
                      aspectRatio: 1,
                    }}
                    alt="Back image"
                    fadeIn={false}
                  />
                </BackSide>
              </Flippy>
            </div>
            <div
              onClick={() => {
                if (!isFlipped6) {
                  setIsFlipped2(false)
                  setIsFlipped1(false)
                  setIsFlipped3(false)
                  setIsFlipped5(false)
                  setIsFlipped4(false)

                  setIsFlipped6(true)
                } else {
                  setIsFlipped6(false)
                }
                setTimeout(() => setIsFlipped6(false), 3000)

              }}
            >
              <Flippy flipDirection="horizontal" isFlipped={isFlipped6}>
                <FrontSide>
                  <Img
                    fluid={data.About.nodes[8].childImageSharp.fluid}
                    alt="Front image"
                  />
                </FrontSide>
                <BackSide>
                  <Img
                    fluid={{
                      ...data.PUC.nodes[0].childImageSharp.fluid,
                      aspectRatio: 1,
                    }}
                    alt="Back image"
                    fadeIn={false}
                  />
                </BackSide>
              </Flippy>
            </div>
            <Link to={'/Gallery/Achievements'}>
              <Img
                fluid={{
                  ...data.About.nodes[9].childImageSharp.fluid,
                  aspectRatio: 1,
                }}
              />
            </Link>
            <Link to="https://www.facebook.com/ch.s.srinivas">
              <Img
                fluid={{
                  ...data.About.nodes[10].childImageSharp.fluid,
                  aspectRatio: 1,
                }}
              />
            </Link>
          </HomeLayout>
        </div>
      </Slider>
    </Layout>
  )
}

export default About
